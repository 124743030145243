import React, { useLayoutEffect, useState } from "react"
import { graphql } from "gatsby"
import { loadStripe } from '@stripe/stripe-js'

import {
  Article,
  ArticleHeader,
  ArticleSection,
} from "../styledComponents/article"

import ProductPurchaseSuccessList from "../components/ProductPurchaseSuccessList";
import Layout from "../components/layout"
import ProgramForm from "../components/programRegisterForm";

const stripe = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const PaymentSuccess = ({ data, location }) => {
  const pageTitle = data.wpPage.title
  const [customer , setCustomer] = useState({})
  const [session , setSession] = useState({})
  const [products , setProducts] = useState([])
  

  const getStripeSession = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('sessionID') 
    if(myParam){
      fetch(`/.netlify/functions/stripe-customer?sessionID=${myParam}`)
  .then(response => response.json())
  .then((data) => {
    setSession(data.msg)
    setCustomer(data.customer)
    setProducts(data.productsList)
  })
      const stripePromise= await loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      getStripeSession()
      return
    }
  }, [])

  return (
    <Layout displayNavBorder>
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader>
          <h1 itemProp="headline">Payment successful</h1>
        </ArticleHeader>

          <ArticleSection itemProp="articleBody">
            <p>Thank you {customer && customer.name} for signing up to KaarmiBeing, this is what you have purchased:</p>
            <ProductPurchaseSuccessList data={products} />
            <p>We are very excited to share this unique program with you and ask you to <strong>please complete the registration form below</strong> to confirm your booking on this workshop.</p> <p>Once this has been submited you will receive an email with the information and a zoom link.</p>
            <p>If you have any questions then please do get in touch with us and we will be happy to assist you.</p>
            <p>We look forward to seeing you at the KaarmiBeing online workshop!</p>
            <p>Warmest wishes,<br />
            The KaarmiBeing Team</p>
            <ProgramForm />
          </ArticleSection>
      </Article>
    </Layout>
  )
}

export default PaymentSuccess

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "payment-success" }) {
      title
      content
      dateGmt
    }
  }
`

import React from "react"

const ProductPurchaseSuccessListeMarkup = ({ data }) => {

  return (
  <div>
      {data.map((item, index) => (
        <ul key={index}>
            <li key={index}>{item["name"] || item["metadata"]["type"]}</li>        
        </ul>
      ))}
  </div>
  )
}

export default ProductPurchaseSuccessListeMarkup


import React, { useEffect, useLayoutEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledSelectWrapper,
  StyledSelect,
  StyledOption,
  ErrorMessage,
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
import addToMailchimp from 'gatsby-plugin-mailchimp'


const ProgramForm = ( ) => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)
  
  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }

    if (!values.emailAddress) {
      errors.emailAddress = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address"
    }
    if (!values.telephone) {
      errors.telephone = "Required"
    }

    if (!values.program) {
      errors.program = "Required"
    }

    if (!values.howHear) {
      errors.howHear = "Required"
    }
   
    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.kaarmibeing.com/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `%BE7ISvKz@jjFTnQNwr4Pxgn`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      telephone: "",
      howHear: "",
      program: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        emailAddress,
        telephone,
        howHear,
        program,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      
      if(values.program === 'level-1-july'){
        values.program['name'] = 'true'
      }
      // addToMailchimp(values.emailAddress, values)
      axios.post("/.netlify/functions/formHandler" , {firstName , lastName ,emailAddress:emailAddress.toLowerCase() , telephone , howHear , program})
      .then(data => {
        console.log(data)
      })
      .catch(console.log)
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("emailAddress", emailAddress)
      bodyFormData.set("telephone", telephone)
      bodyFormData.set("program", program)
      bodyFormData.set("howHear", howHear)
      
      
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.kaarmibeing.com/wp-json/contact-form-7/v1/contact-forms/703/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/program/thank-you")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useLayoutEffect(() => {
    
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit} marginTop>
    <h2>Registration form</h2>
    <StyledFieldset>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="firstName">
        <StyledLabelHeader
            required
            isEmpty={errors.firstName && touched.firstName}
          > 
            First Name
          </StyledLabelHeader>
          <StyledInput
            id="firstName"
            name="firstName"
            type="text"
            onChange={handleChange}
            value={values.firstName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.firstName && touched.firstName ? (
          <span className="error-message">{errors.firstName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="lastName">
        <StyledLabelHeader
            required
            isEmpty={errors.lastName && touched.lastName}
          > 
            Last Name
          </StyledLabelHeader>
          <StyledInput
            id="lastName"
            name="lastName"
            type="text"
            onChange={handleChange}
            value={values.lastName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.lastName && touched.lastName ? (
          <span className="error-message">{errors.lastName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="emailAddress">
        <StyledLabelHeader
            required
            isEmpty={errors.emailAddress && touched.emailAddress}
          > 
            Email Address
          </StyledLabelHeader>
          <StyledInput
            id="emailAddress"
            name="emailAddress"
            type="email"
            onChange={handleChange}
            value={values.emailAddress}
            
            onBlur={handleBlur("emailAddress")}
            required
          />
        </StyledLabel>
        {errors.emailAddress && touched.emailAddress ? (
          <span className="error-message">{errors.emailAddress}</span>
        ) : null}
      </StyledFormElementWrapper>
      
      <StyledFormElementWrapper>
          <StyledLabel htmlFor="telephone">
            <StyledLabelHeader
            required
            isEmpty={errors.telephone && touched.telephone}
          > 
            Phone Number</StyledLabelHeader>
            
            <StyledInput
              id="telephone"
              name="telephone"
              type="tel"
              onChange={handleChange}
              value={values.telephone}
              placeholder="+44 (0)7400 123456"
              onBlur={handleBlur("telephone")}
              required
            />
          </StyledLabel>
          {errors.telephone && touched.telephone ? (
          <span className="error-message">{errors.telephone}</span>
        ) : null}
        </StyledFormElementWrapper>
          
        <StyledSelectWrapper required>
          <StyledFormElementWrapper>
            <StyledLabelHeader
              required
              isEmpty={errors.program && touched.program}
            >
              Program?
            </StyledLabelHeader>

            <StyledSelect
              defaultValue="default"
              name="program"
              id="program"
              onChange={handleChange}
              onBlur={handleBlur}
              required
            >
              <StyledOption value="default" disabled hidden>
                Registering for program?
              </StyledOption>  
              <StyledOption value="2" name="group[21265][2]">Level 1 - July</StyledOption>
              <StyledOption value="level-1-september">Level 1 - September</StyledOption>
              <StyledOption value="mastery-septemebr">Mastery - September</StyledOption>
              <StyledOption value="corporate">Corporate</StyledOption>
              <StyledOption value="personal-consultation">Personal Consultation</StyledOption>
            </StyledSelect>
            {errors.program && touched.program ? (
            <span className="error-message">{errors.program}</span>
          ) : null}
          </StyledFormElementWrapper>
        </StyledSelectWrapper>

        <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="howHear">
            <StyledLabelHeader
              required
              isEmpty={errors.howHear && touched.howHear}
            >How did you hear about us?</StyledLabelHeader>
              <StyledSelect
                defaultValue="default"
                id="howHear"
                name="howHear"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                >
                <StyledOption value="default" disabled hidden>Please select?</StyledOption>  
                <StyledOption value="a friend">From a friend</StyledOption>
                <StyledOption value="Facebook">FaceBook</StyledOption>
                <StyledOption value="Google">Google</StyledOption>
                <StyledOption value="LinkedIn">LinkedIn</StyledOption>
                <StyledOption value="HI CHI">HI CHI</StyledOption>
              </StyledSelect>
              {errors.howHear && touched.howHear ? (
            <span className="error-message">{errors.howHear}</span>
          ) : null}
            </StyledLabel>
          </StyledFormElementWrapper>
        </StyledSelectWrapper>
        
    </StyledFieldset>
    <StyledButton
      type="submit"
      value="Send Message"
      disabled={isSubmitting || !isFormValid}
    //   onClick={() => analyticClickEvent("Demo Form Submitted")}
    >
      Submit
      </StyledButton>
    {messageSent && !isSuccessMessage && (
      <ErrorMessage>
        <p>Something went wrong please try again.</p>
      </ErrorMessage>
    )}
  </StyledForm>
  )
}

export default ProgramForm
